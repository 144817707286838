<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="query.stationMacId"
          placeholder="站点"
          clearable
          filterable
        >
          <template v-for="(item, index) in stationList.list">
            <el-option
              v-if="item.id != ''"
              :label="item.stationName"
              :value="item.stationMacId"
            ></el-option>
          </template>
        </el-select>
      </el-col>

       <el-col :span="4">
        <el-input v-model="query.fullBatteryMacId" placeholder="满仓电池">
        </el-input>
      </el-col>

       <el-col :span="4">
        <el-input v-model="query.emptyBatteryMacId" placeholder="空仓电池">
        </el-input>
      </el-col>

       <el-col :span="4">
        <el-input v-model="query.realName" placeholder="用户姓名">
        </el-input>
      </el-col>

       <el-col :span="4">
        <el-input v-model="query.phone" placeholder="手机号码">
        </el-input>
      </el-col>

      <el-col :span="4">
        <el-select v-model="query.eventType" placeholder="换电类型" clearable>
          <el-option v-for="(item, index) in this.const.EXCHANGE_TYPE" :value="item.value" :label="item.name"></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select v-model="query.result" placeholder="结果" clearable>
          <el-option v-for="(item, index) in this.const.EXCHANGE_RESULT" :value="item.value" :label="item.name"></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="doExportOpenDoorList" v-auth="this.per.EXPORT_OPEN_DOOR_RECORD">导出开门记录</el-button>
      </el-col>
    </el-row>
    <UserEventRecord
      ref="UserEventRecord"
      :listQuery="query"
      :pageable="true"
    ></UserEventRecord>
  </div>
</template>

<script>
import UserEventRecord from "@/components/view-components/user-event-record";
import { mapState } from "vuex";
import moment from "moment";
import { exportOpenDoorList } from "@/api/order";

export default {
  components: { UserEventRecord },
  computed: mapState({
    stationList: (state) => {
      return state.station.stationList || { list: [], map: {} };
    },
  }),
  data() {
    let startTime = moment()
      .startOf("M")
      .format("YYYY-MM-DD HH:mm:ss");
      console.log(startTime);
    let endTime = moment().endOf("M").format("YYYY-MM-DD HH:mm:ss");
    return {
      dateRange: [startTime, endTime],
      stationName: [],
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
       
      },
      total: 0,
    };
  },

  mounted() {
    this.getList();
    this.$store.dispatch("fetchStationAll");
  },

  methods: {
    doExportOpenDoorList() {
      let queryData ={
        startTime: this.query.startTime,
        endTime: this.query.endTime,
        stationId: -1,
        userId: -1
      }
      exportOpenDoorList(queryData).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${queryData.startTime}-${queryData.endTime}开门记录.xlsx`
        );
      });
    },
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
      }
    },

    getList(current) {
      this.$refs["UserEventRecord"].getList(current);
    },
  },
};
</script>
