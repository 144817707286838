<template>
  <div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
    <el-table-column label="事件编号" prop="id"></el-table-column>

      <el-table-column label="柜子名称" prop="stationName"></el-table-column>

      <el-table-column label="柜子编号" prop="stationMacId"></el-table-column>

      <el-table-column label="用户" prop="realName">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="goDetail(scope.row.userId)"
          >
            {{ scope.row.realName }}
          </span>
        </template>
      </el-table-column>

       <el-table-column label="换电类型" prop="eventType" width="100px">
        <template #default="scope">
           <eb-badge :target="scope.row.eventType" :list="this.const.EXCHANGE_TYPE"></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="仓门情况" prop="emptyDoorId" width="250px">
        <template #default="scope">
          <div>空仓：{{scope.row.emptyDoorId}}， macId：{{scope.row.emptyBatteryMacId}}</div>
          <div>满仓：{{scope.row.fullDoorId}}， macId：{{scope.row.fullBatteryMacId}}</div>
        </template>
      </el-table-column>

       <el-table-column label="换电状态" prop="status" width="100px">
        <template #default="scope">
          <eb-badge :target="scope.row.status" :list="this.const.EXCHANGE_STATUS"></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="操作结果" prop="result"  width="100px">
        <template #default="scope">
            <eb-badge
            :list="this.const.EXCHANGE_RESULT"
            :target="scope.row.result"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="开始时间" prop="startTime">
        <template #default="scope">
          <span>{{this.$filter.dateFormat(scope.row.startTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="结束时间" prop="endTime">
         <template #default="scope">
          <span>{{this.$filter.dateFormat(scope.row.endTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="信息" prop="remark"></el-table-column>
     
    </el-table>

    <el-pagination
      v-if="pageable"
      layout="total,prev, pager, next,jumper"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { toRefs, toRef, reactive } from "vue";
import { validatenull } from "@/common/validate";
import { fetchUserEventList } from "@/api/order";
import { useRouter } from "vue-router";

export default {
  props: ["listQuery", "pageable"],

  setup(props, context) {
    let listQuery = toRef(props, "listQuery");
    let router = useRouter();
    const state = reactive({
      total: 0,
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      listQuery: listQuery,
    });

    const goDetail = (id) => {
      router.push("/user/detail/" + id);
    };
    const getList = (current) => {
      if (!validatenull(current)) {
        console.log("===========",current);
        state.pages.pageIndex = current;
      }

      fetchUserEventList({ ...state.listQuery, ...state.pages }).then((res) => {
        let list = res.data.data.records || [];
        state.total = res.data.data.total;
        state.dataSource = list;
      });
    };

    return {
      ...toRefs(state),
      listQuery: listQuery.value,
      getList,
      goDetail,
    };
  },
};
</script>